import { supportsTouchEvents } from 'detect-it'

import { clamp } from '@/utils/maths'

export default {
	name: 'slideshow',
	component(props = { groupBy: 1, autoPlayInterval: undefined, disableLg: false }) {
		const { groupBy, autoPlayInterval, disableLg } = props
		const wrapper = this.$refs.wrapper

		const dots = []
		if (this.$refs.dots) {
			dots.push(...this.$refs.dots?.children)
		}

		return {
			index: 0,
			maxIndex: 0,
			x: 0,
			destX: 0,
			pages: 0,
			easing: 0.11,
			autoPlayEasing: 0.08,
			hasTouched: false,
			loop: false,
			buttonState: -1,
			interval: undefined,
			intersectionObserver: undefined,
			autoPlayStopped: false,
			containerBound: {},
			bounds: [],
			children: [],

			handleTouchStart() {
				this.stopAutoPlay()
				this.hasTouched = true
			},

			handlePrev() {
				this.stopAutoPlay()
				const count = this.bounds.length
				const nextIndex = this.index >= groupBy ? this.index - groupBy : this.loop ? this.index + count - groupBy : 0
				this.scrollTo(nextIndex)
			},

			handleNext(forceLoop = false) {
				if (!forceLoop) this.stopAutoPlay()
				const count = this.bounds.length
				const nextIndex =
					this.index < count - groupBy ? this.index + groupBy : this.loop || forceLoop ? (this.index + groupBy) % count : count - groupBy

				this.scrollTo(nextIndex)
			},

			handleNavChange(index: number) {
				this.stopAutoPlay()
				this.scrollTo(index)
			},

			stopAutoPlay() {
				clearTimeout(this.interval)
				this.autoPlayStopped = true
			},

			scrollTo(index: number) {
				if (!this.maxIndex || !this.containerBound) return

				this.hasTouched = false

				this.index = clamp(index, 0, this.maxIndex)

				const boundsIndex = index * groupBy
				this.destX = Math.min(
					this.bounds[boundsIndex].left,
					this.bounds[this.bounds.length - 1].left + this.bounds[this.bounds.length - 1].width - this.containerBound.width
				)

				if (wrapper && supportsTouchEvents) {
					wrapper.scrollTo({ left: this.destX, behavior: 'smooth' })
				} else if (wrapper) {
					wrapper.classList.remove('snap-x')
				}

				this.buttonState = this.index === 0 ? -1 : this.index >= this.maxIndex - 1 ? 1 : 0
			},

			init() {
				this.children = wrapper.children

				this.$watch('$store.global.isMinicartVisible', (isVisible) => {
					const chatbox = document.getElementById('ShopifyChat')
					if (chatbox) {
						chatbox.style.transform = isVisible ? 'translateY(200%)' : ''
					}
				})

				const getItemsBoundingRect = () => {
					const observer = new IntersectionObserver(entries => {
						// we can't rely on this.containerBound.left in case the slideshow is in an animated container, such as a slideout
						if (this.containerBound?.left !== entries[0].left) {
							this.containerBound = wrapper.getBoundingClientRect()
						}

						this.bounds = entries
							.map(entry => ({
								left: entry.boundingClientRect.left - this.x - this.containerBound.left,
								width: entry.boundingClientRect.width
							}))
							.sort(rect => rect.left)
						this.maxIndex = this.bounds.length - 1
						const delta = this.bounds[this.maxIndex].left + this.bounds[this.maxIndex].width - this.containerBound.width
						for (let i = this.maxIndex; i >= 0; i--) {
							const rect = this.bounds[i]
							if (rect.left - rect.width < delta) {
								this.maxIndex = i
								break
							}
						}

						observer.disconnect()
					})

					if (this.children) {
						for (const item of this.children) {
							if (!item.className.match(/\b\w*hidden\w*\b/g)) observer.observe(item)
						}
					}

					if (dots.length > 0) {
						this.$watch('index', value => {
							dots.forEach((el: HTMLElement, index: number) => {
								if (index === value) {
									el.classList.add('is-active')
									el.ariaCurrent = 'true'
								} else {
									el.classList.remove('is-active')
									el.ariaCurrent = 'false'
								}
							})
						})
					}
				}

				// update index based on intersection observer
				const handleChildEnter = (elements: IntersectionObserverEntry[]) => {
					const element = elements[0]
					if (element.isIntersecting && this.hasTouched) {
						this.index = Math.floor(element.target.__i / groupBy)
					}
				}

				this.childrenIntersectionObserver = new IntersectionObserver(handleChildEnter, {
					root: this.$el,
					threshold: 0.4
				})

				Array.from(this.children).forEach((el, index) => {
					if (el) {
						el.__i = index
						this.childrenIntersectionObserver.observe(el)
					}
				})

				this.$useRaf(() => {
					if (!supportsTouchEvents) {
						this.x += (this.destX - this.x) * (!this.autoPlayStopped && autoPlayInterval ? this.autoPlayEasing : this.easing)

						if (wrapper) {
							wrapper.scrollTo({ left: this.x, behavior: 'instant' })
						}
					}
				})

				// update sizing when dom is displayed for the first time
				const handleEnter = (elements: IntersectionObserverEntry[]) => {
					const element = elements[0]
					if (element.isIntersecting) {
						if (this.intersectionObserver) this.intersectionObserver.disconnect()

						this.$useResize(wrapper, e => {
							this.containerBound = e.boundingClientRect || e.target.getBoundingClientRect()
							getItemsBoundingRect()
						})
					}
				}

				this.intersectionObserver = new IntersectionObserver(handleEnter, {})
				this.intersectionObserver.observe(this.$el)

				if (autoPlayInterval && !this.autoPlayStopped) {
					this.interval = setInterval(() => {
						this.handleNext(true)
					}, autoPlayInterval)
				}
			},

			destroy() {
				clearInterval(this.interval)
				if (this.childrenIntersectionObserver) this.childrenIntersectionObserver.disconnect()
				if (this.intersectionObserver) this.intersectionObserver.disconnect()
			}
		}
	}
}
